<template>
    <div id="datePicker">
        <span style="color:red">{{errormsg}}</span>
        <v-container  id="searchForm__container">
            <!-- <v-expand-transition> -->
                <!-- <div v-show="expand" no-gutters> -->
                    <v-row>
                       
                         <v-col>
                            <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                no-title
                                v-model="sdate"
                                label="Select Start Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click="dateTimer()"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="sdate"
                            @input="menu2 = false"
                            no-title scrollable 
                            :events="availableHolidayDates"
                            ref="datepicker"
                            event-color="white"
                            @click:month="dateTimer()"
                            ></v-date-picker>
                        </v-menu>
                        </v-col>

                        <v-col >
                            <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                no-title
                                v-model="edate"
                                label="Select End Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click="dateTimer()"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="edate"
                            @input="menu3 = false"
                            no-title scrollable 
                            ref="datepicker"
                            :events="availableHolidayDates"
                            event-color="white"
                            @click:month="dateTimer()"
                            ></v-date-picker>
                        </v-menu>
                        </v-col>
                        
                       
                         
                    </v-row>
           
                               
        </v-container>
    </div>
</template>

<script>
//  import moment from 'moment'
import holiday from '../../../assets/americanholiday.json'
export default {
    name: 'datePicker',
    data: () => ({
        
        client_endpoint:'',
        errorMessage:{
            "date_er_message":"Please select start date and end date"
        },
        
        sdate:'',    
       
          modal: false,
          endatemodel:false,
          errormsg:'',
          edate:'',
          sdate:'',
          menu2: false,
          menu3: false,
          availableHolidayDates: [],
          holidayControl: 0,
    }),
    
    props: [
        'formType',
        'stateName',
    ],
    mounted(){
      let view = this        
        this.sdate = "";
        this.edate = "";

    },
    async updated(){
      console.log('DDDD');
      this.holidayControl = this.holidayControl + 1;
      if(this.holidayControl === 1){
        await this.$nextTick();
        const prevBtn = this.$refs.datepicker.$el.querySelector('.v-btn[aria-label="Previous month"]')
        prevBtn.addEventListener('click', () => {
          this.dateTimer();
        })
        const nextBtn = this.$refs.datepicker.$el.querySelector('.v-btn[aria-label="Next month"]')
        nextBtn.addEventListener('click', () => {
          this.dateTimer();
        })
      }
    },
    computed: {
        
    },
    methods: {
         formatDate(date) {
      var [date, month, year] = date.toLocaleDateString().split('/')
      return `${year}-${month}-${date}`;
    },
        searchData() {
            var view = this
            let d1 = new Date(this.sdate)
            let d2 = new Date(this.edate)
            
           
            if (d1.getTime() > d2.getTime())
            {
                alert("Start Date should not be greater then end date ")

            }
            else{
                let searchobj = {}
                searchobj["sdate"] = this.sdate
                searchobj["edate"] = this.edate
              
                view.$emit('search',searchobj)
            }
            
        },
        reset: function() {
            var view = this
            // this.sdate = ''
            // this.edate = ''
            view.select = ''
            view.$emit('reset')
        },
        getDate: function(dt){
            
            console.log('testing'+dt)
            this.$refs.dialog.save(dt)
            //  view.$emit('date',dt)

        },
        dateTimer(){
          let view = this;
          let a =[];
          const currentYear = new Date().getFullYear();
          for(let i=0; i<holiday.length; i++){
            if(view.$refs.datepicker !== undefined && view.$refs.datepicker.tableYear !== currentYear){
              a.push(holiday[i].occurrence.replace('2020', view.$refs.datepicker.tableYear.toString()));
            }
            else{
              a.push(holiday[i].occurrence.replace('2020', currentYear.toString()));
            }
          }
          view.availableHolidayDates = a;
          setTimeout(() =>view.getEventsList(), 100);
        },
        getEventsList(){
          var eventsDates = document.querySelectorAll('.v-date-picker-table .v-btn');
          for(var i=0; i<eventsDates.length; i++){
                let nextSibling = eventsDates[i].children;
                if(nextSibling[1] != undefined){
                  nextSibling[0].style.color = "#ff0000"
                }     
              }
              this.holidayControl = 0;
            },
    },
    watch: {
    }
}
</script>

<style>
.v-date-picker-table--date tbody tr td{
  &:nth-child(1) {
    .v-btn--disabled {
      .v-btn__content {
        color: #ff0000;
      }
    }
    .v-btn__content {
      color: #ff0000;
    }
  }
  &:nth-child(7) {
    .v-btn--disabled {
      .v-btn__content {
        color: #ff0000;
      }
    }
    .v-btn__content {
      color: #ff0000;
    }
  }
}
.v-date-picker-table .v-btn .v-date-picker-table__events {
  opacity:0;
}
</style>

<style scoped lang="sass">
@import './../../../sass/searchform'
#searchForm__container div.col
  padding-top: 1px
  padding-bottom: 1px
#searchForm__container div.row
  margin-top:0px
#searchForm__container
  padding-top:0px


</style>
